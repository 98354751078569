import React, { Component } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

import { randomPodcast } from './Utilities';

// Components
import Container from './Container';
import Headline from './Headline';
import PodcastList from './PodcastList';
import Footer from './Footer';
import Nav from './Nav';
import DemoVideo from './DemoVideo';

import './Tabs.css';

const apiUrl = process.env.REACT_APP_API_URL;

class App extends Component {
  state = {};

  componentDidMount() {
    this.handleCategoryAllClick(); // 默认加载全部数据
  }

  fetchData = category => {
    let url = `${apiUrl}get/category/${category}`;
    console.log(`>>>>>> ${url}`);
    if (category === 'all') {
      url = `${apiUrl}get/${category}`;
    }
    axios
      .get(url)
      .then(response => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ [category]: response.data, selectedCategory: category });
        // eslint-disable-next-line react/destructuring-assignment
        console.log(this.state);
        if (category === 'all') randomPodcast(response.data);
      })
      .catch(error => {
        if (error.response) {
          // 请求已发出，但服务器响应状态码不在 2xx 范围内
          console.error(`Error fetching ${category} data`, error.response.data);
          console.error(`Status code: ${error.response.status}`);
        } else if (error.request) {
          // 请求已发出，但没有收到响应
          console.error('No response received:', error.request);
        } else {
          // 发生了其他错误
          console.error('Error', error.message);
        }
      });
  };

  handleCategoryAllClick = () => {
    this.fetchData('all');
  };

  handleCategory101Click = () => {
    this.fetchData('101');
  };

  handleCategory102Click = () => {
    this.fetchData('102');
  };

  handleCategory103Click = () => {
    this.fetchData('103');
  };

  handleCategory104Click = () => {
    this.fetchData('104');
  };

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    const categoryAllData = this.state.all || [];
    // eslint-disable-next-line react/destructuring-assignment
    const category101Data = this.state['101'] || [];
    // eslint-disable-next-line react/destructuring-assignment
    const category102Data = this.state['102'] || [];
    // eslint-disable-next-line react/destructuring-assignment
    const category103Data = this.state['103'] || [];
    // eslint-disable-next-line react/destructuring-assignment
    const category104Data = this.state['104'] || [];

    return (
      <>
        <Nav />

        <Container>
          <Headline />
          <DemoVideo />
          <br />
          <Tabs>
            <TabList>
              <Tab onClick={this.handleCategoryAllClick}>全部 ({categoryAllData.length})</Tab>
              <Tab onClick={this.handleCategory102Click}>AI</Tab>
              <Tab onClick={this.handleCategory104Click}>游戏</Tab>
              <Tab onClick={this.handleCategory101Click}>搜索引擎</Tab>
              <Tab onClick={this.handleCategory103Click}>学习</Tab>
            </TabList>

            <TabPanel>
              <PodcastList items={categoryAllData} />
            </TabPanel>
            <TabPanel>
              <PodcastList items={category102Data} />
            </TabPanel>
            <TabPanel>
              <PodcastList items={category104Data} />
            </TabPanel>
            <TabPanel>
              <PodcastList items={category101Data} />
            </TabPanel>
            <TabPanel>
              <PodcastList items={category103Data} />
            </TabPanel>
          </Tabs>

          <Footer />
        </Container>
      </>
    );
  }
}

export default App;
