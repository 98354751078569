import { useEffect, useState } from 'react';
import { lowerCase } from '../components/Utilities';

export default (items, filter) => {
  const [filteredItems, setFilteredItems] = useState(items);

  // const filterItem = item => lowerCase(item.name).includes(lowerCase(filter));
  // eslint-disable-next-line arrow-body-style
  const filterItem = item => {
    return (
      lowerCase(item.name).includes(lowerCase(filter)) ||
      item.keys.some(key => lowerCase(key).includes(lowerCase(filter)))
    );
  };

  useEffect(() => {
    setFilteredItems(items.filter(filterItem));
  }, [items, filter]); // 依赖数组，确保 useEffect 只在 items 或 filter 改变时重新执行

  return filteredItems;
};
