/* eslint-disable no-shadow */
import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

const jumpUrl = process.env.REACT_APP_JUMP_URL;

const Card = styled.a`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px;
  border-radius: 8px;
  padding: 8px;
  transition: all 0.2s ease-out 0s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-column-gap: 10px;

  &:hover,
  &:active {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 24px;
  }
`;

const Info = styled.div`
  padding-top: 4px;

  h3 {
    margin-top: 0;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
  }

  p {
    font-size: 14px;
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    hyphens: auto;
  }
`;

// eslint-disable-next-line react/prop-types
const Podcast = ({ url, id, name, description, keys = [] }) => {
  const hostUrl = process.env.REACT_APP_HOST_URL;
  const imageUrl = `${hostUrl}static/images/all/${id}/1.webp`;
  const imageUrl2 = `${hostUrl}static/images/all/${id}/1.svg`;
  const imageUrl3 = `${hostUrl}static/images/all/${id}/1.png`;
  const imageUrlDefault = `${hostUrl}static/images/all/default.webp`;

  const handleError = e => {
    if (e.target.src === imageUrl) {
      // 如果 .webp 图片加载失败，尝试加载 .svg
      e.target.src = imageUrl2;
      console.log(`Failed to load ${imageUrl}, switching to ${imageUrl2}`);
    } else if (e.target.src === imageUrl2) {
      // 如果 .svg 图片加载失败，尝试加载 .png
      e.target.src = imageUrl3;
      console.log(`Failed to load ${imageUrl2}, switching to ${imageUrl3}`);
    } else if (e.target.src === imageUrl3) {
      // 如果 .png 图片也加载失败，加载默认的 fallback 图片
      e.target.src = imageUrlDefault;
      console.log(`Failed to load ${imageUrl3}, switching to default image: ${imageUrlDefault}`);
    }
  };

  // 使用 map 遍历 keys 数组，并为每个元素创建一个 React 元素
  const keysElements = keys.map((key, index) => (
    <span key={index}>
      <a
        href={`${jumpUrl}${key}`}
        target="target"
        style={{ color: 'gray', textDecorationSkipInk: 'none' }}
      >
        <i>{key}</i>
      </a>
      {index < keys.filter(key => key).length - 1 && ', '}
    </span>
  ));

  return (
    <Card href={url} target="_blank" rel="noopener noreferrer">
      <LazyLoad height={100} once>
        <img src={imageUrl} alt={name} width="100" height="100" onError={handleError} />
      </LazyLoad>
      <Info>
        <h3>{name}</h3>
        <p>{description}</p>
        {keysElements}
      </Info>
    </Card>
  );
};

export default Podcast;
