import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 40px;

  p {
    margin: 0;
  }

  video {
    border-radius: 1em;
  }
`;

const DemoVideo = () => (
  <Wrapper>
    <p>
      <video loop playsinline preload="metadata" controls controlsList="nodownload noplaybackrate" disablepictureinpicture width="100%" height="100%"
        poster="https://oss.zaplink.top/website/assets/video/1_cover_2560x1440_400000104.webp"
        src="https://oss.zaplink.top/website/assets/video/1_2560x1440_400000104.mp4">
      </video>
    </p>
  </Wrapper>
);

export default DemoVideo;
